(function ($) {
    'use strict';

    /**
     * loads initial options/values for
     * the navigator select fields
     *
     * @constructor
     */
    $.Navigator = function () {
        getJsonForNavigator();
    };

    /**
     * is a select on change plugin
     * for adding/removing options in related
     * selects
     *
     * @return {*}
     */
    $.fn.select = function () {
        var _relations = $(this).find('option:selected').data('relations'),
            _elem,
            _options = [],
            _lVal = parseInt($('#location').val()),
            _cVal = parseInt($('#category').val());

        // the data-attribute is used for testing on which element
        // is started, that there are no options changed on changing the
        // other select field
        if (typeof $('.navigator').attr('data-select') === 'undefined') {
            $('.navigator').attr('data-select', $(this).attr('id'));
        }

        // this object is stored in the localstorage
        // for detailed usage in uri handling and
        // preselecting the select fields after reloading page
        var _selected = {
            start: $('.navigator').attr('data-select'),
            location: $('#location option:selected').val(),
            category: $('#category option:selected').val(),
        };

        // sets the object in the localstorage
        setStorage(JSON.stringify(_selected), 'selected');

        // if the location is set to initial value
        // set the button as disabled, so that there is no
        // button clicking possible, bcoz no value without
        // a location is given.
        if (_lVal !== -1) {
            $('.navigator button').removeAttr('disabled').addClass('bounceIn');
        } else {
            $('.navigator button').attr('disabled', true).removeClass('bounceIn bounceOut');
        }

        // if the selected field is the location
        // field - possible resetting the categories select
        // and do nothing, if the initial select was the
        // category select
        if ($(this).attr('id') === 'location') {
            _elem = $('#category');

            if ($('.navigator').attr('data-select') === 'category') {
                return void 0;
            }

            if (_lVal === -1) {
                _elem.find('option:not(:first)').remove();
                setOptions(getStorage('data').categories, _elem);
                $('.navigator').removeAttr('data-select');
                removeStorage('selected');
                return void 0;
            }
        }

        // if the selected field is the categories
        // field - possible resetting the location select
        // and do nothing, if the initial select was the
        // location select
        if ($(this).attr('id') === 'category') {
            _elem = $('#location');

            if ($('.navigator').attr('data-select') === 'location') {
                $('.navigator button').addClass('bounceOut');
                return void 0;
            }

            if (_cVal === -1) {
                _elem.find('option:not(:first)').remove();
                setOptions(getStorage('data').locations, _elem);
                $('.navigator').removeAttr('data-select');
                removeStorage('selected');
                $('.navigator button').attr('disabled', true).removeClass('bounceIn bounceOut');
                return void 0;
            }
        }

        // remove all options but the first
        _elem.find('option:not(:first)').remove();

        // add the related options from the
        // selected options data-attribute
        $.each(_relations, function (k, v) {
            _options.push($('<option />').attr('value', v.uri).data('uid', v.uid).text(v.title));

            if (v.title === 'Online') {
                _options.push($('<option />').text('-------').attr('disabled', 'disabled'));
            }
        });

        // we dont need to sort them again, because they are already sorted via php with online first
        // sorting the options alphabetically
        // _options.sort(function(a,b){
        // 	return (a.text().toUpperCase() < b.text().toUpperCase()) ? -1 : (a.text().toUpperCase() > b.text().toUpperCase()) ? 1 : 0;
        // });

        // and append them
        _elem.append(_options);

        // if you start in the category, make sure, there is a location available
        if (_selected.start === 'category' && parseInt($('#location').val()) === -1) {
            var _selectedLocation = getLocation(_selected.location);
            var _selectOption = $('#location option[value*="/standorte/' + _selectedLocation + '"]');

            if (_selectedLocation !== null && _selectOption.length > 0) {
                _selectOption.prop('selected', true);
            } else {
                $('.navigator button').attr('disabled', true).removeClass('bounceIn bounceOut');
            }
        }
    };

    /**
     * the button click listener
     */
    $('.navigator button').on('click', function (e) {
        e.preventDefault();
        var _href = '/';

        if ($('#category').val().indexOf('/') !== -1) {
            _href = $('#category').val();
        } else {
            _href = $('#location').val();
        }

        document.location.href = _href;
    });

    /**
     * Extracts the location identifier from a given URL.
     *
     * @param {string} url - The URL from which the location should be extracted.
     * @return {string|null} The location identifier if found; otherwise, null.
     */
    function getLocation(url) {
        const parts = url.split('/');
        const startIndex = parts.indexOf('standorte') + 1;
        const endIndex = parts.length - 2;

        if (startIndex > 0 && endIndex > startIndex) {
            return parts[startIndex];
        } else {
            return null;
        }
    }

    /**
     * gets the option values from the database via ajax
     * or from the localstorage.
     *
     * first call comes from the ajax action and stores
     * the data in the localstorage.
     *
     * if the ajax call fails, no options are available
     */
    function getJsonForNavigator() {
        var data = getStorage('data');

        if (data && !data.date) {
            data = null;
        } else {
            if (data) {
                var diff = new Date() - new Date(data.date),
                    hours = diff / 1000 / 60 / 60;

                if (hours > 1) {
                    removeStorage('data');
                    data = null;
                }
            }
        }

        if (data === null || data === false) {
            $.ajax({
                url: '/?type=1529930229',
            })
                .done(function (data) {
                    setOptions(data.locations, $('#location'));
                    setOptions(data.categories, $('#category'));
                    data.date = new Date();
                    setStorage(data, 'data');
                })
                .fail(function (e) {
                    var _options = '<option value="-1">' + e.statusText + '</option>';
                    $('#location').append(_options);
                    $('#category').append(_options);
                });
        } else {
            setOptions(data.locations, $('#location'));
            setOptions(data.categories, $('#category'));
        }
    }

    /**
     * function to add the options to the
     * select field
     *
     * @param data
     * @param elem
     */
    function setOptions(data, elem) {
        elem.innerHTML = '';

        var _options = '';
        $.each(data, function (k, v) {
            if (typeof v.uid === 'number') {
                _options +=
                    '<option value="' +
                    v.uri +
                    '" data-uid="' +
                    v.uid +
                    '" data-relations=\'' +
                    JSON.stringify(v.relations) +
                    "'>" +
                    v.title +
                    '</option>';

                if (v.title === 'Online') {
                    _options += '<option disabled="disabled">-------</option>';
                }
            } else {
                _options +=
                    '<option value="' +
                    v.title +
                    '" data-relations=\'' +
                    JSON.stringify(v.relations) +
                    "'>" +
                    v.title +
                    '</option>';
            }
        });

        elem.append(_options);
    }

    /**
     * function to set the data into the localstorage
     * after checking if localstorage is available.
     *
     * @param data
     * @param key
     */
    function setStorage(data, key) {
        try {
            if (typeof Storage !== 'undefined') {
                localStorage.setItem(key, JSON.stringify(data));
            } else {
                // no local storage supported
            }
        } catch (e) {
            // unable to write local storage
            return void 0;
        }
    }

    /**
     * get the data from the localstorage
     *
     * @param key
     * @return {*}
     */
    function getStorage(key) {
        try {
            if (typeof Storage !== 'undefined') {
                if (localStorage.getItem(key) !== null) {
                    return JSON.parse(localStorage.getItem(key));
                } else {
                    return null;
                }
            } else {
                // no local storage supported
            }
        } catch (e) {
            // unable to read local storage
            return null;
        }
    }

    /**
     * removes data from localstorage
     *
     * @param key
     */
    function removeStorage(key) {
        if (typeof Storage !== 'undefined') {
            localStorage.removeItem(key);
        } else {
            // no local storage supported
        }
    }

    /**
     * global plugin for checking the uri
     * depending on the selected fields in
     * the navigator.
     */
    $.checkUri = function () {
        var _storage = getStorage('selected');

        if (!_storage) {
            return void 0;
        }

        var _selected = JSON.parse(getStorage('selected')),
            _active = document.location.pathname;

        if (_active.indexOf(_selected.location) === -1 && _active.indexOf(_selected.category) === -1) {
            removeStorage('selected');
            return void 0;
        }

        if (_selected.start == 'location') {
            $('#location option[value="' + _selected.location + '"]')
                .prop('selected', true)
                .trigger('change');
            $('#category option[value="' + _selected.category + '"]')
                .prop('selected', true)
                .trigger('change');
        }

        if (_selected.start == 'category') {
            $('#category option[value="' + _selected.category + '"]')
                .prop('selected', true)
                .trigger('change');
            $('#location option[value="' + _selected.location + '"]')
                .prop('selected', true)
                .trigger('change');
        }
    };
})(jQuery);
