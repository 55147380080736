(function ($) {
    $.breakpoints.init();

    // init lazy loading
    $.lazy.init();

    $.Navigator();
    $('#location, #category').on('change', function () {
        $(this).select();
    });
    $.checkUri();

    $.cart.init();
    $.sticky.init();
    $.slickslider.init();
    $.jumpTo.init();
    $.truncate.init();
    $.random.init();
    $.footerMap.init();
    $.history.init();
    $.formValidation.init();
    $.form.init();
    $.shy.init();
    $.breadcrumb.init();
    $.anchorShrink.init();
    $.dataLayer.init();
    $.mobileNav.init();
    $.paymentSelect.init();
    $.bookNowButtons.init();
    $.rowClick.init();
    $.datePicker.init();
    $.dflipInit.init();

    $('.js-loadVideo').gdpr({
        url: '//www.youtube-nocookie.com/embed/',
        element: '.embed-responsive',
        data: 'youtube',
        params: '?rel=0&amp;showinfo=0&amp;color=red&amp;autoplay=1',
        remove: '.embed-responsive-item',
        width: 640,
        height: 385,
    });

    $('.js-loadMap').gdpr({
        url: '//www.google.com/maps/embed/v1/place',
        element: '.embed-responsive',
        data: 'map',
        params: '&key=AIzaSyCFSA2BGI7EFNeTJQuLBCfn5sdn_Wxr_9s',
        remove: '.embed-responsive-item',
        width: 640,
        height: 385,
    });

    $('[data-reactive]').nearby();

    $.sticky.stickyHeader();

    // sets the testimonials height in the footer for
    // gradient positioning
    document.documentElement.style.setProperty(
        '--testimonial-height',
        Math.ceil($('#footer .slick-initialized').parent().outerHeight()) + 'px',
    );

    const youtubeElems = document.querySelectorAll('.tx-supi__youtube-iframe');
    youtubeElems.forEach((elem) => {
        elem.addEventListener('youTubeAllowed', (event) => {
            const youtubeIframe = event.target;
            youtubeIframe.setAttribute('width', '100%');
            youtubeIframe.setAttribute('height', '100%');
            youtubeIframe.setAttribute('allowfullscreen', '');
            youtubeIframe.setAttribute(
                'allow',
                'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
            );
            youtubeIframe.setAttribute('referrerPolicy', 'strict-origin-when-cross-origin');
            youtubeIframe.src = youtubeIframe.src.replace('youtube-nocookie', 'youtube');
        });
    });
})(jQuery);

$(window).on('resize', function () {
    $.anchorShrink.init();
    $.sticky.stickyHeader();

    // sets the testimonials height in the footer for
    // gradient positioning
    document.documentElement.style.setProperty(
        '--testimonial-height',
        Math.ceil($('#footer .slick-initialized').parent().outerHeight()) + 'px',
    );
});

const navigationTop = document.querySelector('.navigation-top');

const _sideNavWidth = parseInt($('.navigation-top__location').width());
if (_sideNavWidth > 250) {
    const _root = document.documentElement;
    _root.style.setProperty('--location-nav-width', _sideNavWidth * 1.5 + 'px');
}

const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting === false) {
            $('.navigation-anchor .location, #topTop').toggleClass('visible');

            if ($('.navigation-anchor').length <= 0) {
                $('.navigation-top__location').addClass('absolute');

                if ($('.navigation-top__location li').length === 1) {
                    $('.navigation-top__location').addClass('single');
                }
            }
        } else {
            $('.navigation-anchor .location, #topTop').removeClass('visible');
            $('.navigation-top__location').removeClass('absolute single');
        }
    });
});

observer.observe(navigationTop);

$(window).on('scroll', function () {
    if ($(this).scrollTop() > 0) {
        $('.navigation-top').addClass('sm-sticky');
    } else {
        $('.navigation-top').removeClass('sm-sticky');
    }
});
