(function ($) {
    'use strict';

    $.jumpTo = new Object({
        init: function () {
            this.enrichAnchorsWithSections();
            this.jump();

            var that = this;
            setTimeout(function () {
                that.checkHash();
            }, 500);
        },

        enrichAnchorsWithSections: function () {
            if ($('form[name="cart"]').length > 0) {
                var _text = '';
                if ($('.courses.event .courses_group').length > 0 && $('.courses.course .courses_group').length <= 0) {
                    _text = 'Anmelden';
                } else {
                    _text = 'Termine & Buchen';
                }

                var _courses = $('<li />').append(
                    $('<a />')
                        .attr('href', document.location.pathname + '#cCartForm')
                        .attr('data-content', 'CartForm')
                        .addClass('highlight')
                        .append($('<span />').text(_text)),
                );

                $('ul.sections').prepend(_courses);
            }

            if ($('#creasons').length > 0) {
                var _reasons = $('<li />').append(
                    $('<a />')
                        .attr('href', document.location.pathname + '#creasons')
                        .text('Vorteile')
                        .attr('data-content', 'reasons'),
                );

                $('ul.sections').append(_reasons);
            }
        },

        jump: function () {
            // jump to nav
            $(document).on('click', '.navigation-anchor ul:not(.location) a, main [href^="#"]', function (e) {
                e.preventDefault();
                var _toTop = 0,
                    _href = $(this).attr('href'),
                    _header = $('#header').outerHeight(),
                    _id,
                    _ce;

                if (
                    $(this).parent().index() === 1 &&
                    $(this).parent().prop('tagName') === 'LI' &&
                    $(this).parent().parent().hasClass('sections')
                ) {
                    _toTop = 0;
                } else if (typeof $(this).data('content') !== 'undefined') {
                    _id = $(this).data('content');
                    _ce = $('#c' + _id).offset().top;
                    _toTop = _ce - _header;
                } else if (_href.charAt(0) === '#') {
                    _id = _href;
                    _ce = $(_id).offset().top;
                    _toTop = _ce - _header;
                } else {
                    _toTop = 0;
                }

                $('html, body')
                    .stop()
                    .animate(
                        {
                            scrollTop: _toTop,
                        },
                        500,
                        'swing',
                        function () {
                            // window.history.pushState('', '', _href);
                        },
                    );
            });

            // sidebar widget nav
            $(document).on('click', '[data-scrollto]', function (e) {
                e.preventDefault();

                var _id = $(this).data('scrollto'),
                    _ce = $('#' + _id).offset().top,
                    _header = $('.slicknav_menu').is(':visible')
                        ? $('.slicknav_menu').outerHeight()
                        : $('#header').outerHeight(),
                    _toTop = _ce - _header;

                $('html, body')
                    .stop()
                    .animate(
                        {
                            scrollTop: _toTop,
                        },
                        500,
                        'swing',
                        function () {
                            $('#' + _id)
                                .find('[aria-expanded].collapsed')
                                .eq(0)
                                .trigger('click');
                        },
                    );
            });

            // rte anchor jumpto link
            $(document).on('click', '.jump-to-courses', function (e) {
                e.preventDefault();

                if ($('#cCartForm').length > 0) {
                    var _ce = $('#cCartForm').offset().top,
                        _header = $('.slicknav_menu').is(':visible')
                            ? $('.slicknav_menu').outerHeight()
                            : $('#header').outerHeight(),
                        _toTop = _ce - _header;

                    $('html, body')
                        .stop()
                        .animate(
                            {
                                scrollTop: _toTop,
                            },
                            500,
                            'swing',
                            function () {
                                $('#cCartForm').find('[aria-expanded].collapsed').eq(0).trigger('click');
                            },
                        );
                }
            });
        },

        checkHash: function () {
            if (typeof document.location.hash !== 'undefined' && document.location.hash !== '') {
                var $element = $(document.location.hash);

                if ($element.length > 0) {
                    var _ce = $element.offset().top,
                        _header = $('#header').outerHeight(),
                        _toTop = _ce - _header;

                    $('html, body').stop().animate({ scrollTop: _toTop });
                }
            }
        },
    });
})(jQuery);
